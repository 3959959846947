import { ChangeEvent, FocusEvent, forwardRef, ForwardRefRenderFunction, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Translations } from '../../../models/Translation';
import { SupportedLanguage } from '../../../types/Languages';
import { Input, InputProps } from './Input';
import { ariaAttributeProps, dataAttributeProps } from '../../../utils/ComponentUtils';
import MultiTextField, { MultiTextFieldProps } from './MultiTextField';
import TranslatableInputButtons from '../TranslatableInputButtons';
import InlineEditor, { InlineEditorProps } from './InlineEditor';
import { useTranslation } from 'react-i18next';
import { DynamicDataInterfaceHandle } from './InputWithDynamicData';

type TranslateableProps = {
  translations: Translations;
  translationKey: string;
  onTranslationsChange: (newTranslations: Translations) => void;
  fallbackValue?: string;
};

type Props = TranslateableProps &
  (
    | ({
        inputType?: undefined;
      } & Omit<InputProps, 'value' | 'onChange'>)
    | ({
        inputType: 'singleline';
      } & Omit<InputProps, 'value' | 'onChange'>)
    | ({
        inputType: 'multiline';
      } & Omit<MultiTextFieldProps, 'value' | 'onChange'>)
    | ({
        inputType: 'inline';
      } & Omit<InlineEditorProps, 'value' | 'onChange'>)
  );

type Handle = {
  validate: () => boolean;
} & DynamicDataInterfaceHandle;

const TranslatableInput: ForwardRefRenderFunction<Handle, Props> = (props, ref) => {
  const { translations, translationKey, onTranslationsChange, inputType = 'singleline', fallbackValue, ...rest } = props;
  const {
    i18n: { language: userLanguage },
  } = useTranslation();
  const [language, setLanguage] = useState(userLanguage as SupportedLanguage);
  const [show, setShow] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [savedPosition, setSavedPosition] = useState<number | null>(null);

  const value = useMemo(() => {
    return translations?.[language]?.[translationKey] || fallbackValue || '';
  }, [fallbackValue, language, translationKey, translations]);

  useEffect(() => {
    setLanguage(userLanguage as SupportedLanguage);
  }, [userLanguage]);

  const onChangeInternal = useCallback(
    (event: string & ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLTextAreaElement>) => {
      const newTranslations = {
        ...(translations || {}),
        [language]: {
          ...((translations || {})[language] || {}),
          [translationKey]: (typeof event as unknown) === 'string' ? event : event.target.value,
        },
      };
      onTranslationsChange(newTranslations);
    },
    [language, onTranslationsChange, translationKey, translations],
  );

  useImperativeHandle(
    ref,
    () => ({
      validate() {
        const valid = !!value?.trim();
        setErrorState(!valid);
        return valid;
      },
      savePosition: () => {
        const input = document.activeElement as HTMLInputElement;
        setSavedPosition(input.selectionStart);
      },
      insertPlaceholder: (placeholder: string) => {
        let result = value;
        result = result.slice(0, savedPosition ?? 0) + placeholder + result.slice((savedPosition ?? 0) + 1); // +1 to remove the triggering `/`
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChangeInternal(result as any);
      },
    }),
    [onChangeInternal, savedPosition, value],
  );

  const onFocusInternal = useCallback(
    (event: FocusEvent<HTMLInputElement> & FocusEvent<HTMLTextAreaElement>) => {
      setShow(true);
      rest.onFocus && rest.onFocus(event);
    },
    [rest],
  );

  const onBlurInternal = useCallback(
    (event: FocusEvent<HTMLInputElement> & FocusEvent<HTMLTextAreaElement>) => {
      setShow(false);
      rest.onBlur && rest.onBlur(event);
      setLanguage(userLanguage as SupportedLanguage);
    },
    [rest, userLanguage],
  );

  const Component = useMemo(() => {
    switch (inputType) {
      case 'inline':
        return InlineEditor;
      case 'multiline':
        return MultiTextField;
      case 'singleline':
      default:
        return Input;
    }
  }, [inputType]);

  return (
    <Component
      {...rest}
      {...dataAttributeProps(props)}
      {...ariaAttributeProps(props)}
      value={value}
      onChange={onChangeInternal}
      onFocus={onFocusInternal}
      onBlur={onBlurInternal}
      errorState={errorState}
    >
      <Component.Slot name="trailing">
        <div className={`mx-2 flex items-center gap-1 rounded-lg bg-white ${show ? '' : inputType === 'singleline' ? 'hidden' : 'invisible'}`}>
          <TranslatableInputButtons selected={language} errorState={errorState} onChange={setLanguage} />
        </div>
      </Component.Slot>
    </Component>
  );
};

export default forwardRef(TranslatableInput);
